export const clientUserDepartmentDropdownItems = [
  {
    'id': 'department-not-profiled',
    'value': 'Not Profiled'
  },
  {
    'id': 'department-board-secretary-legal',
    'value': 'Board Secretary / Legal'
  },
  {
    'id': 'department-investor-relations-finance',
    'value': 'Investor Relations / Finance'
  },
  {
    'id': 'department-ceo',
    'value': 'CEO'
  },
  {
    'id': 'department-board-member',
    'value': 'Board Member'
  },
  {
    'id': 'department-outside-advisor',
    'value': 'Outside Advisor'
  }
];


export const clientUserTypeDropdownItems = [
  {
    'id': 'client-user-type-view-only',
    'value': 'View Only'
  },
  {
    'id': 'client-user-type-editor',
    'value': 'Editor'
  },
  {
    'id': 'client-user-type-primary-editor',
    'value': 'Primary Editor'
  }
];


export const countryDropdownItems = [
  { 'id': 'country-argentina', 'value': 'Argentina' },
  { 'id': 'country-australia', 'value': 'Australia' },
  { 'id': 'country-austria', 'value': 'Austria' },
  { 'id': 'country-bahamas', 'value': 'Bahamas' },
  { 'id': 'country-bahrain', 'value': 'Bahrain' },
  { 'id': 'country-barbados', 'value': 'Barbados' },
  { 'id': 'country-belgium', 'value': 'Belgium' },
  { 'id': 'country-brazil', 'value': 'Brazil' },
  { 'id': 'country-canada', 'value': 'Canada' },
  { 'id': 'country-china', 'value': 'China' },
  { 'id': 'country-cyprus', 'value': 'Cyprus' },
  { 'id': 'country-czech-republic', 'value': 'Czech Republic' },
  { 'id': 'country-south-korea', 'value': 'South Korea' },
  { 'id': 'country-denmark', 'value': 'Denmark' },
  { 'id': 'country-egypt', 'value': 'Egypt' },
  { 'id': 'country-finland', 'value': 'Finland' },
  { 'id': 'country-france', 'value': 'France' },
  { 'id': 'country-germany', 'value': 'Germany' },
  { 'id': 'country-greece', 'value': 'Greece' },
  { 'id': 'country-hungary', 'value': 'Hungary' },
  { 'id': 'country-india', 'value': 'India' },
  { 'id': 'country-indonesia', 'value': 'Indonesia' },
  { 'id': 'country-ireland', 'value': 'Ireland' },
  { 'id': 'country-israel', 'value': 'Israel' },
  { 'id': 'country-italy', 'value': 'Italy' },
  { 'id': 'country-japan', 'value': 'Japan' },
  { 'id': 'country-kazakhstan', 'value': 'Kazakhstan' },
  { 'id': 'country-kuwait', 'value': 'Kuwait' },
  { 'id': 'country-liechtenstein', 'value': 'Liechtenstein' },
  { 'id': 'country-luxembourg', 'value': 'Luxembourg' },
  { 'id': 'country-malaysia', 'value': 'Malaysia' },
  { 'id': 'country-malta', 'value': 'Malta' },
  { 'id': 'country-mexico', 'value': 'Mexico' },
  { 'id': 'country-monaco', 'value': 'Monaco' },
  { 'id': 'country-netherlands', 'value': 'Netherlands' },
  { 'id': 'country-new-zealand', 'value': 'New Zealand' },
  { 'id': 'country-norway', 'value': 'Norway' },
  { 'id': 'country-oman', 'value': 'Oman' },
  { 'id': 'country-pakistan', 'value': 'Pakistan' },
  { 'id': 'country-panama', 'value': 'Panama' },
  { 'id': 'country-philippines', 'value': 'Philippines' },
  { 'id': 'country-poland', 'value': 'Poland' },
  { 'id': 'country-portugal', 'value': 'Portugal' },
  { 'id': 'country-qatar', 'value': 'Qatar' },
  { 'id': 'country-russia', 'value': 'Russia' },
  { 'id': 'country-san-marino', 'value': 'San Marino' },
  { 'id': 'country-saudi-arabia', 'value': 'Saudi Arabia' },
  { 'id': 'country-singapore', 'value': 'Singapore' },
  { 'id': 'country-south-africa', 'value': 'South Africa' },
  { 'id': 'country-spain', 'value': 'Spain' },
  { 'id': 'country-sri-lanka', 'value': 'Sri Lanka' },
  { 'id': 'country-sweden', 'value': 'Sweden' },
  { 'id': 'country-switzerland', 'value': 'Switzerland' },
  { 'id': 'country-thailand', 'value': 'Thailand' },
  { 'id': 'country-trinidad-and-tobago', 'value': 'Trinidad and Tobago' },
  { 'id': 'country-turkey', 'value': 'Turkey' },
  { 'id': 'country-united-arab-emirates', 'value': 'United Arab Emirates' },
  { 'id': 'country-united-kingdom', 'value': 'United Kingdom' },
  { 'id': 'country-united-states-of-america', 'value': 'United States' },
  { 'id': 'country-vietnam', 'value': 'Vietnam' },
  { 'id': 'country-hong-kong', 'value': 'Hong Kong' },
  { 'id': 'country-taiwan', 'value': 'Taiwan' }
];


export const regionDropdownItems = [
  { 'id': 'region-asia', 'value': 'Asia' },
  { 'id': 'region-europe', 'value': 'Europe' },
  { 'id': 'region-united-states', 'value': 'United States' },
  { 'id': 'region-others', 'value': 'Others' }
];


export const departmentDropdownItems = [
  {
    'id': 'department-not-profiled',
    'value': 'Not Profiled'
  },
  {
    'id': 'department-board=secretary-legal',
    'value': 'Board Secretary / Legal'
  },
  {
    'id': 'department-investor-relations-finance',
    'value': 'Investor Relations / Finance'
  },
  {
    'id': 'department-ceo',
    'value': 'CEO'
  },
  {
    'id': 'department-board-member',
    'value': 'Board Member'
  },
  {
    'id': 'department-outside-advisor',
    'value': 'Outside Advisor'
  }
];


export const relationshipStatusDropdownItems = [
  {
    'id': 'relationship-status-strong',
    'value': 'Strong',
    'chipColor': 'success'
  },
  {
    'id': 'relationship-status-standard',
    'value': 'Standard',
    'chipColor': 'warning'
  },
  {
    'id': 'relationship-status-weak',
    'value': 'Weak',
    'chipColor': 'danger'
  },
  {
    'id': 'relationship-status-unknown',
    'value': 'Unknown',
    'chipColor': 'unknown'
  }
];


export const clientTypeDropdownItems = [
  {
    'id': 'client-type-corporate-profile',
    'value': 'Corporate'
  },
  {
    'id': 'automatic-renewal-no',
    'value': 'Non-Corporate'
  }
];


export const automaticRenewalDropdownItems = [
  {
    'id': 'automatic-renewal-yes',
    'value': 'Yes'
  },
  {
    'id': 'automatic-renewal-no',
    'value': 'No'
  }
];


export const esgDataRatingsDropdownItems = [
  {
    'id': 'esg-data-ratings-yes',
    'value': 'Yes'
  },
  {
    'id': 'esg-data-ratings-no',
    'value': 'No'
  }
];


export const clientMenuItems = [
  {'id': 'dashboard', 'text': 'Dashboard', 'icon': 'dashboard', 'href': '/dashboard', 'module_id': 'dashboard'},
  {'id': 'investors', 'text': 'Investors', 'icon': 'investors', 'href': '/investors', 'module_id': 'investors'},
  {'id': 'proxy-advisors', 'text': 'Proxy Advisors & Ratings', 'icon': 'proxy-advisors', 'href': '/proxy-advisors', 'module_id': 'proxy_advisors'},
  {'id': 'esg-topics', 'text': 'ESG Topics', 'icon': 'esg-topics', 'href': '/esg-topics', 'module_id': 'esg_topics'},
  {'id': 'engagements', 'text': 'Engagements', 'icon': 'engagements', 'href': '/engagements', 'module_id': 'engagements'},
  {'id': 'docusearch', 'text': 'DocuSearch', 'icon': 'docusearch', 'href': '/docusearch', 'module_id': 'docusearch'},
  {'id': 'quickvote', 'text': 'QuickVote', 'icon': 'quickvote', 'href': '/quickvote', 'module_id': 'quickvote'},
  {'id': 'research', 'text': 'ReSearch', 'icon': 'research', 'href': '/research', 'module_id': 'research'},
  // {'id': 'voting-projections', 'text': 'Voting Projections', 'icon': 'voting-projections'}
];

export const accountManagerMenuItems = [
  {'id': 'my-clients', 'text': 'My Clients', 'icon': 'my-clients', 'href': '/clients'},
  // {'id': 'notifications', 'text': 'Notifications', 'icon': 'notifications'}
];






/* Investor Details - Summary */

/* Investor Type */
export const investorTypeDropdownItems = [
	{
    'id': 'investor-type-asset-manager',
    'value': 'Asset Manager'
  },
  {
    'id': 'investor-type-hedge-fund',
    'value': 'Hedge Fund'
  },
  {
    'id': 'investor-type-pension-fund',
    'value': 'Pension Fund'
  },
  {
    'id': 'investor-type-private-bank-prime-broker',
    'value': 'Private Bank / Prime Broker'
  },
  {
    'id': 'investor-type-sovereign-wealth-fund',
    'value': 'Sovereign Wealth Fund'
  },
  {
    'id': 'investor-type-governance-code',
    'value': 'Governance Code'
  },
  {
    'id': 'investor-type-insurer',
    'value': 'Insurer'
  },
  {
    'id': 'investor-strategic-investor',
    'value': 'Strategic Investor'
  },
  {
    'id': 'investor-type-unknown',
    'value': 'Unknown'
  }
];

/* Investment Style */
export const investorInvestmentStyleDropdownItems = [
	{
    'id': 'investment-style-majority-active',
    'value': 'Majority Active'
  },
  {
    'id': 'investment-style-majority-passive',
    'value': 'Majority Passive'
  },
  {
    'id': 'investment-style-fixed-income',
    'value': 'Fixed Income'
  },
  {
    'id': 'investment-style-not-profiled',
    'value': 'Not Profiled'
  },
  {
    'id': 'investment-style-not-applicable',
    'value': 'Not Applicable'
  },
  {
    'id': 'investment-style-unknown',
    'value': 'Unknown'
  }
];

/* AUM */
export const investorAUMDropdownItems = [
	{
    'id': 'aum-0-50-billion',
    'value': '$ 0-50 billion'
  },
  {
    'id': 'aum-50-200-billion',
    'value': '$ 50-200 billion'
  },
  {
    'id': 'aum-200-500-billion',
    'value': '$ 200-500 billion'
  },
  {
    'id': 'aum-greater-500-billion',
    'value': '$ >500 billion'
  },
  {
    'id': 'aum-not-applicable',
    'value': 'Not Applicable'
  },
  {
    'id': 'aum-unknown',
    'value': 'Unknown'
  }
];

/* Voting Decision Maker */
export const investorVotingDecisionMakerDropdownItems = [
  {
    'id': 'voting-decision-maker-client-request',
    'value': 'Client Request'
  },
  {
    'id': 'voting-decision-maker-fund-manager',
    'value': 'Fund Manager'
  },
  {
    'id': 'voting-decision-maker-outsourced-engagement-firm',
    'value': 'Outsourced - Engagement Firm'
  },
  {
    'id': 'voting-decision-maker-outsourced-proxy-advisor',
    'value': 'Outsourced - Proxy Advisor'
  },
  {
    'id': 'voting-decision-maker-stewardship-team',
    'value': 'Stewardship Team'
  },
  {
    'id': 'voting-decision-maker-stewardship-team-fund-manager',
    'value': 'Stewardship Team with Fund Manager'
  },
  {
    'id': 'voting-decision-maker-not-voting',
    'value': 'Not Voting'
  },
  {
    'id': 'voting-decision-maker-outsourced-external-manager',
    'value': 'Outsourced - External Manager'
  },
  {
    'id': 'voting-decision-maker-unknown',
    'value': 'Unknown'
  }
];

/* Proxy Advisor */
export const investorProxyAdvisorDropdownItems = [
  {
    'id': 'investor-proxy-advisor-afg',
    'value': 'AFG'
  },
  {
    'id': 'investor-proxy-advisor-ecgs',
    'value': 'ECGS'
  },
  {
    'id': 'investor-proxy-advisor-glass-lewis',
    'value': 'Glass Lewis'
  },
  {
    'id': 'investor-proxy-advisor-iss',
    'value': 'ISS'
  },
  {
    'id': 'investor-proxy-advisor-iss-sri',
    'value': 'ISS-SRI'
  },
  {
    'id': 'investor-proxy-advisor-ivis',
    'value': 'IVIS'
  },
  {
    'id': 'investor-proxy-advisor-pirc',
    'value': 'PIRC'
  },
  {
    'id': 'investor-proxy-advisor-proxinvest',
    'value': 'Proxinvest'
  },
  {
    'id': 'investor-proxy-advisor-local-proxy-advisor',
    'value': 'Local Proxy Advisor'
  },
  {
    'id': 'investor-proxy-advisor-not-disclosed',
    'value': 'Not Disclosed'
  },
  {
    'id': 'investor-proxy-advisor-no-advisor',
    'value': 'No Advisor'
  },
  {
    'id': 'investor-proxy-advisor-unknown',
    'value': 'Unknown'
  }
];

/* Proxy Advisor Reliance */
export const investorProxyAdvisorRelianceDropdownItems = [
  {
    'id': 'investor-proxy-advisor-reliance-high',
    'value': 'High'
  },
  {
    'id': 'investor-proxy-advisor-reliance-medium',
    'value': 'Medium'
  },
  {
    'id': 'investor-proxy-advisor-reliance-low',
    'value': 'Low'
  },
  {
    'id': 'investor-proxy-advisor-reliance-low-less-strict',
    'value': 'Low - Less Strict'
  },
  {
    'id': 'investor-proxy-advisor-reliance-low-stricter',
    'value': 'Low - Stricter'
  },
  {
    'id': 'investor-proxy-advisor-reliance-not-applicable',
    'value': 'Not Applicable'
  },
  {
    'id': 'investor-proxy-advisor-reliance-unknown',
    'value': 'Unknown'
  }
];

/* Voting */
export const investorVotingDropdownItems = [
  {
    'id': 'voting-yes',
    'value': 'Yes'
  },
  {
    'id': 'voting-no',
    'value': 'No'
  },
  {
    'id': 'voting-case-by-case',
    'value': 'Case-by-Case'
  },
  {
    'id': 'voting-unknown',
    'value': 'Unknown'
  }
];

/* Activist */
export const investorActivistDropdownItems = [
  {
    'id': 'activist-yes',
    'value': 'Yes'
  },
  {
    'id': 'activist-no',
    'value': 'No'
  },
  {
    'id': 'activist-not-determined',
    'value': 'Not Determined'
  },
  {
    'id': 'activist-unknown',
    'value': 'Unknown'
  }
];

/* Receptivity to Activists */
export const investorReceptivityToActivistsDropdownItems = [
  {
    'id': 'receptivity-to-activists-high',
    'value': 'High'
  },
  {
    'id': 'receptivity-to-activists-medium',
    'value': 'Medium'
  },
  {
    'id': 'receptivity-to-activists-low',
    'value': 'Low'
  },
  {
    'id': 'receptivity-to-activists-not-determined',
    'value': 'Not Determined'
  },
  {
    'id': 'receptivity-to-activists-not-profiled',
    'value': 'Not Profiled'
  },
  {
    'id': 'receptivity-to-activists-unknown',
    'value': 'Unknown'
  }
];

/* ESG Focus */
export const investorESGFocusDropdownItems = [
  {
    'id': 'esg-focus-high',
    'value': 'High'
  },
  {
    'id': 'esg-focus-medium',
    'value': 'Medium'
  },
  {
    'id': 'esg-focus-low',
    'value': 'Low'
  },
  {
    'id': 'esg-focus-not-profiled',
    'value': 'Not Profiled'
  },
  {
    'id': 'esg-focus-unknown',
    'value': 'Unknown'
  }
];

/* ESG UNPRI */
export const investorUNPRIDropdownItems = [
  {
    'id': 'esg-unpri-yes',
    'value': 'Yes'
  },
  {
    'id': 'esg-unpri-no',
    'value': 'No'
  },
  {
    'id': 'esg-unpri-not-profiled',
    'value': 'Not Profiled'
  },
  {
    'id': 'esg-unpri-unknown',
    'value': 'Unknown'
  }
];

/* ESG Nature 100 */
export const investorNature100DropdownItems = [
  {
    'id': 'esg-nature-100-yes',
    'value': 'Yes'
  },
  {
    'id': 'esg-nature-100-no',
    'value': 'No'
  },
  {
    'id': 'esg-nature-100-not-profiled',
    'value': 'Not Profiled'
  },
  {
    'id': 'esg-nature-100-unknown',
    'value': 'Unknown'
  }
];

/* ESG TNFD */
export const investorTNFDDropdownItems = [
  {
    'id': 'esg-tnfd-yes',
    'value': 'Yes'
  },
  {
    'id': 'esg-tnfd-no',
    'value': 'No'
  },
  {
    'id': 'esg-tnfd-not-profiled',
    'value': 'Not Profiled'
  },
  {
    'id': 'esg-tnfd-unknown',
    'value': 'Unknown'
  }
];

/* ESG Climate Coalition */
export const investorClimateCoalitionDropdownItems = [
  {
    'id': 'esg-climate-coalition-yes',
    'value': 'Yes'
  },
  {
    'id': 'esg-climate-coalition-no',
    'value': 'No'
  },
  {
    'id': 'esg-climate-coalition-not-profiled',
    'value': 'Not Profiled'
  },
  {
    'id': 'esg-climate-coalition-unknown',
    'value': 'Unknown'
  }
];

/* ESG PRI - Advance */
export const investorPRIAdvanceDropdownItems = [
  {
    'id': 'esg-pri-advance-yes',
    'value': 'Yes'
  },
  {
    'id': 'esg-pri-advance-no',
    'value': 'No'
  },
  {
    'id': 'esg-pri-advance-not-profiled',
    'value': 'Not Profiled'
  },
  {
    'id': 'esg-pri-advance-unknown',
    'value': 'Unknown'
  }
];

/* ESG CDP - SBTi */
export const investorCDPSBTiDropdownItems = [
  {
    'id': 'esg-cdp-sbti-yes',
    'value': 'Yes'
  },
  {
    'id': 'esg-cdp-sbti-no',
    'value': 'No'
  },
  {
    'id': 'esg-cdp-sbti-not-profiled',
    'value': 'Not Profiled'
  },
  {
    'id': 'esg-cdp-sbti-unknown',
    'value': 'Unknown'
  }
];

/* ESG WDi */
export const investorWDiDropdownItems = [
  {
    'id': 'esg-wdi-yes',
    'value': 'Yes'
  },
  {
    'id': 'esg-wdi-no',
    'value': 'No'
  },
  {
    'id': 'esg-wdi-not-profiled',
    'value': 'Not Profiled'
  },
  {
    'id': 'esg-wdi-unknown',
    'value': 'Unknown'
  }
];

/* ESG in Fixed Income */
export const investorESGInFixedIncomeDropdownItems = [
  {
    'id': 'esg-in-fixed-income-yes',
    'value': 'Yes'
  },
  {
    'id': 'esg-in-fixed-income-no',
    'value': 'No'
  },
  {
    'id': 'esg-in-fixed-income-not-profiled',
    'value': 'Not Profiled'
  },
  {
    'id': 'esg-in-fixed-income-unknown',
    'value': 'Unknown'
  }
];

/* E&S Shareholder Proposals Filed */
export const investorShareholderProposalsFiledDropdownItems = [
  {
    'id': 'es-shareholder-proposals-filed-yes',
    'value': 'Yes'
  },
  {
    'id': 'es-shareholder-proposals-filed-no',
    'value': 'No'
  },
  {
    'id': 'es-shareholder-proposals-filed-not-determined',
    'value': 'Not Determined'
  },
  {
    'id': 'es-shareholder-proposals-filed-unknown',
    'value': 'Unknown'
  }
];

/* ESG Main Service Providers */
export const investorESGMainServiceProvidersDropdownItems = [
  {
    'id': 'esg-main-service-provider-msci',
    'value': 'MSCI'
  },
  {
    'id': 'esg-main-service-provider-sustainalytics',
    'value': 'Sustainalytics'
  },
  {
    'id': 'esg-main-service-provider-moodys',
    'value': 'Moody\'s ESG Solutions'
  },
  {
    'id': 'esg-main-service-provider-iss-esg',
    'value': 'ISS ESG'
  },
  {
    'id': 'esg-main-service-provider-bloomberg-esg',
    'value': 'Bloomberg ESG'
  },
  {
    'id': 'esg-main-service-provider-reprisk',
    'value': 'RepRisk'
  },
  {
    'id': 'esg-main-service-provider-trucost',
    'value': 'Trucost'
  },
  {
    'id': 'esg-main-service-provider-none',
    'value': 'None'
  },
  {
    'id': 'esg-main-service-provider-not-disclosed',
    'value': 'Not Disclosed'
  },
  {
    'id': 'esg-main-service-provider-unknown',
    'value': 'Unknown'
  }
];

/* Client Contact Department */
export const clientContactDepartmentDropdownItems = [
  {
    'id': 'contact-department-stewardship',
    'value': 'Stewardship'
  },
  {
    'id': 'contact-department-responsible-investment',
    'value': 'Responsible Investment'
  },
  {
    'id': 'contact-department-fund-management',
    'value': 'Fund Management'
  },
  {
    'id': 'contact-department-corporate-actions',
    'value': 'Corporate Actions'
  }
];







export const meetingTypeDropdownItems = [
  {
    'id': 'meeting-type-general-meeting',
    'value': 'General Meeting'
  },
  {
    'id': 'meeting-type-governance',
    'value': 'Governance'
  },
  {
    'id': 'meeting-type-environment-social',
    'value': 'Environment & Social'
  },
  {
    'id': 'meeting-type-esg',
    'value': 'ESG'
  },
  {
    'id': 'meeting-type-special-situations',
    'value': 'Special Situations'
  },
];

export const engagementRatingDropdownItems = [
  {
    'id': 'engagement-rating-positive',
    'value': 'Positive',
    'chipColor': 'success'
  },
  {
    'id': 'engagement-rating-neutral',
    'value': 'Neutral',
    'chipColor': 'unknown'
  },
  {
    'id': 'engagement-rating-negative',
    'value': 'Negative',
    'chipColor': 'danger'
  }
];


export const meetingRequestEmailStatusDropdownItems = [
  {
    'id': 'meeting-request-email-status-sent',
    'value': 'Sent',
    'headerColor': 'success',
    'chipColor': 'success'
  },
  {
    'id': 'meeting-request-email-status-not-sent',
    'value': 'Not Sent',
    'headerColor': 'unknown',
    'chipColor': 'unknown'
  }
];

export const meetingRequestMeetingStatusDropdownItems = [
  {
    'id': 'meeting-request-meeting-status-pending',
    'value': 'Pending',
    'headerColor': 'unknown',
    'chipColor': 'unknown'
  },
  {
    'id': 'meeting-request-meeting-status-not-in-progress',
    'value': 'In Progress',
    'headerColor': 'info',
    'chipColor': 'info'
  },
  {
    'id': 'meeting-request-meeting-status-not-confirmed',
    'value': 'Confirmed',
    'headerColor': 'success',
    'chipColor': 'success'
  },
  {
    'id': 'meeting-request-meeting-status-not-declined',
    'value': 'Declined',
    'headerColor': 'danger',
    'chipColor': 'danger'
  },
  {
    'id': 'meeting-request-meeting-status-cancelled',
    'value': 'Cancelled',
    'headerColor': 'warning',
    'chipColor': 'warning'
  }
];

export const meetingRequestMeetingStatusDeactivatedDropdownItems = [
  {
    'id': 'meeting-request-meeting-status-not-eligible',
    'value': '-'
  }
];

export const shareholderCommunicationEmailStatusDropdownItems = [
  {
    'id': 'shareholder-communication-email-status-sent',
    'value': 'Sent',
    'headerColor': 'success',
    'chipColor': 'success'
  },
  {
    'id': 'shareholder-communication-email-status-not-sent',
    'value': 'Not Sent',
    'headerColor': 'unknown',
    'chipColor': 'unknown'
  }
];


/* Help Section */

/* Help Section - Contact Form */

export const helpCategoryDropdownItems = [
	{
    'id': 'help-category-technical-support',
    'value': 'Technical Support (Raise an Issue)'
  },
  {
    'id': 'help-category-platform-use',
    'value': 'Platform Use'
  },
  {
    'id': 'help-category-other',
    'value': 'Other (incl. General Meeting related issues)'
  }
];

export const platformUseAreaDropdownItems = [
  {
    'id': 'platform-use-area-my-investors',
    'value': 'My Investors'
  },
  {
    'id': 'platform-use-area-aqtion-investor-profile',
    'value': 'AQTION Investor Profile'
  },
  {
    'id': 'platform-use-area-custom-investor-profile',
    'value': 'Custom Investor Profile'
  },
  {
    'id': 'platform-use-area-engagements',
    'value': 'Engagements'
  }
];