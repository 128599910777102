import { Fragment, useState } from 'react';
import { clientTypeDropdownItems, countryDropdownItems, departmentDropdownItems, regionDropdownItems, relationshipStatusDropdownItems } from '../../../../utils/constants';
import useInputValidation from '../../../../hooks/use-input-validation';
import { commaSeperatedValueToNumber, getValueFromVariable } from '../../../../utils/helpers';

import Button from '../../../../components/UI/Button/Button';
import DateInput from '../../../../components/UI/DateInput/DateInput';
import Dropdown from '../../../../components/UI/Dropdown/Dropdown';
import NumberInput from '../../../../components/UI/NumberInput/NumberInput';
import TextInput from '../../../../components/UI/TextInput/TextInput';

import styles from './ClientDetailsGeneralInfo.module.css';
import Checkbox from '../../../../components/UI/Checkbox/Checkbox';

const ClientDetailsGeneralInfo = (props) => {

  const [generalInformationData, setGeneralInformationData] = useState(props.generalInformationData);
  const [mainContactData, setMainContactData] = useState(props.mainContactData);
  const [moduleManagementData, setModuleManagementData] = useState(props.moduleManagementData);

  /* Company Information */
  const [profilePictureUrl, setProfilePictureUrl] = useState(getValueFromVariable(generalInformationData['profile_picture_url']));

  const [name, setName, isNameValid, hasNameError, nameChangeHandler, nameBlurHandler] =
    useInputValidation(getValueFromVariable(generalInformationData['name']), (name) => name.trim() !== '');

  const [clientType, setClientType, isClientTypeValid, hasClientTypeError, clientTypeChangeHandler, clientTypeBlurHandler] =
    useInputValidation(getValueFromVariable(generalInformationData['client_type']), (clientType) => true);

  const [region, setRegion, isRegionValid, hasRegionError, regionChangeHandler, regionBlurHandler] =
    useInputValidation(getValueFromVariable(generalInformationData['region']), (region) => region.trim() !== '');

  const [country, setCountry, isCountryValid, hasCountryError, countryChangeHandler, countryBlurHandler] =
    useInputValidation(getValueFromVariable(generalInformationData['country']), (country) => country.trim() !== '');

  const [totalNumOfShares, setTotalNumOfShares, isTotalNumOfSharesValid, hasTotalNumOfSharesError, totalNumOfSharesChangeHandler, totalNumOfSharesBlurHandler] =
    useInputValidation(getValueFromVariable(generalInformationData['total_num_of_shares']), (totalNumOfShares) => totalNumOfShares.toString().trim() !== '');

  const [dateNumOfShares, setDateNumOfShares] = useState(getValueFromVariable(generalInformationData['date_num_of_shares'], 'date'));

  /* Main Contact */
  const [fullname, setFullname, isFullnameValid, hasFullnameError, fullnameChangeHandler, fullnameBlurHandler] =
    useInputValidation(getValueFromVariable(mainContactData['fullname']), (fullname) => fullname.trim() !== '');

  const [jobTitle, setJobTitle, isJobTitleValid, hasJobTitleError, jobTitleChangeHandler, jobTitleBlurHandler] =
    useInputValidation(getValueFromVariable(mainContactData['job_title']), (jobTitle) => jobTitle.trim() !== '');

  const [department, setDepartment, isDepartmentValid, hasDepartmentError, departmentChangeHandler, departmentBlurHandler] =
    useInputValidation(getValueFromVariable(mainContactData['department']), (department) => department.trim() !== '');

  const [email, setEmail, isEmailValid, hasEmailError, emailChangeHandler, emailBlurHandler] =
    useInputValidation(getValueFromVariable(mainContactData['email']), (email) => email.trim() !== '');

  const [phoneNumber, setPhoneNumber] = useState(getValueFromVariable(mainContactData['phone_number']));
  const [relationshipStatus, setRelationshipStatus] = useState(getValueFromVariable(mainContactData['relationship_status']));
  
  /* Module Management */
  const [isDashboardModuleActive, setIsDashboardModuleActive] = useState(moduleManagementData['dashboard']);
  const [isInvestorsModuleActive, setIsInvestorsModuleActive] = useState(moduleManagementData['investors']);
  const [isInvestorContactsModuleActive, setIsInvestorContactsModuleActive] = useState(moduleManagementData['investor_contacts']);
  const [isProxyAdvisorsModuleActive, setIsProxyAdvisorsModuleActive] = useState(moduleManagementData['proxy_advisors']);
  const [isProxyAdvisorContactsModuleActive, setIsProxyAdvisorContactsModuleActive] = useState(moduleManagementData['proxy_advisor_contacts']);
  const [isESGTopicsModuleActive, setIsESGTopicsModuleActive] = useState(moduleManagementData['esg_topics']);
  const [isEngagementMeetingNotesModuleActive, setIsEngagementMeetingNotesModuleActive] = useState(moduleManagementData['engagement_meeting_notes']);
  const [isEngagementOtherNotesModuleActive, setIsEngagementOtherNotesModuleActive] = useState(moduleManagementData['engagement_other_notes']);
  const [isEngagementMeetingRequestsModuleActive, setIsEngagementMeetingRequestsModuleActive] = useState(moduleManagementData['engagement_meeting_requests']);
  const [isEngagementShareholderCommunicationsModuleActive, setIsEngagementShareholderCommunicationsModuleActive] = useState(moduleManagementData['engagement_shareholder_communications']);
  const [isDocuSearchModuleActive, setIsDocuSearchModuleActive] = useState(moduleManagementData['docusearch']);
  const [isQuickVoteModuleActive, setIsQuickVoteModuleActive] = useState(moduleManagementData['quickvote']);
  const [isReSearchModuleActive, setIsReSearchModuleActive] = useState(moduleManagementData['research']);

  const [isEditMode, setIsEditMode] = useState(false);

  const cancelEditHandler = () => {
    setIsEditMode(false);

    /* Company Information */
    setProfilePictureUrl(getValueFromVariable(generalInformationData['profile_picture_url']));
    setName(getValueFromVariable(generalInformationData['name']));
    setRegion(getValueFromVariable(generalInformationData['region']));
    setCountry(getValueFromVariable(generalInformationData['country']));
    setTotalNumOfShares(getValueFromVariable(generalInformationData['total_num_of_shares']));
    setDateNumOfShares(getValueFromVariable(generalInformationData['date_num_of_shares'], 'date'));
    setClientType(getValueFromVariable(generalInformationData['client_type']));

    /* Main Contact */
    setFullname(getValueFromVariable(mainContactData['fullname']));
    setJobTitle(getValueFromVariable(mainContactData['job_title']));
    setDepartment(getValueFromVariable(mainContactData['department']));
    setEmail(getValueFromVariable(mainContactData['email']));
    setPhoneNumber(getValueFromVariable(mainContactData['phone_number']));
    setRelationshipStatus(getValueFromVariable(mainContactData['relationship_status']));

    /* Module Management */
    setIsDashboardModuleActive(moduleManagementData['dashboard']);
    setIsInvestorsModuleActive(moduleManagementData['investors']);
    setIsInvestorContactsModuleActive(moduleManagementData['investor_contacts']);
    setIsProxyAdvisorsModuleActive(moduleManagementData['proxy_advisors']);
    setIsProxyAdvisorContactsModuleActive(moduleManagementData['proxy_advisor_contacts']);
    setIsESGTopicsModuleActive(moduleManagementData['esg_topics']);
    setIsEngagementMeetingNotesModuleActive(moduleManagementData['engagement_meeting_notes']);
    setIsEngagementOtherNotesModuleActive(moduleManagementData['engagement_other_notes']);
    setIsEngagementMeetingRequestsModuleActive(moduleManagementData['engagement_meeting_requests']);
    setIsEngagementShareholderCommunicationsModuleActive(moduleManagementData['engagement_shareholder_communications']);
    setIsDocuSearchModuleActive(moduleManagementData['docusearch']);
    setIsQuickVoteModuleActive(moduleManagementData['quickvote']);
    setIsReSearchModuleActive(moduleManagementData['research']);
  }

  const isGeneralInfoFormValid = () => {
    nameBlurHandler();
    regionBlurHandler();
    countryBlurHandler();
    totalNumOfSharesBlurHandler();
    fullnameBlurHandler();
    jobTitleBlurHandler();
    departmentBlurHandler();
    emailBlurHandler();

    const isGeneralInformationFormValid = isNameValid && isRegionValid && isCountryValid && isTotalNumOfSharesValid && isClientTypeValid;
    const isMainContactFormValid = isFullnameValid && isJobTitleValid && isDepartmentValid && isEmailValid;

    return (isGeneralInformationFormValid && isMainContactFormValid);
  }

  const updateGeneralInfoHandler = () => {
    if(isGeneralInfoFormValid()) {
      const updatedGeneralInformationData = {
        'name': name,
        'region': region,
        'country': country,
        'profile_picture_url': profilePictureUrl,
        'client_type': clientType
      };
      if(clientType === 'Corporate') {
        updatedGeneralInformationData['total_num_of_shares'] = commaSeperatedValueToNumber(totalNumOfShares);
        updatedGeneralInformationData['date_num_of_shares'] = dateNumOfShares === '' ? null : dateNumOfShares.toDateString();
      }
      else {
        updatedGeneralInformationData['total_num_of_shares'] = 0;
        updatedGeneralInformationData['date_num_of_shares'] = null;
      }
  
      const updatedMainContactData = {
        'fullname': fullname,
        'job_title': jobTitle,
        'department': department,
        'email': email,
        'phone_number': phoneNumber,
        'relationship_status': relationshipStatus
      };

      const updatedModuleManagementData = {
        'dashboard': isDashboardModuleActive,
        'investors': isInvestorsModuleActive,
        'investor_contacts': isInvestorContactsModuleActive,
        'proxy_advisors': isProxyAdvisorsModuleActive,
        'proxy_advisor_contacts': isProxyAdvisorContactsModuleActive,
        'esg_topics': isESGTopicsModuleActive,
        'engagement_meeting_notes': isEngagementMeetingNotesModuleActive,
        'engagement_other_notes': isEngagementOtherNotesModuleActive,
        'engagement_meeting_requests': isEngagementMeetingRequestsModuleActive,
        'engagement_shareholder_communications': isEngagementShareholderCommunicationsModuleActive,
        'docusearch': isDocuSearchModuleActive,
        'quickvote': isQuickVoteModuleActive,
        'research': isReSearchModuleActive
      };

      setGeneralInformationData(updatedGeneralInformationData);
      setMainContactData(updatedMainContactData);
      setModuleManagementData(updatedModuleManagementData);
      setIsEditMode(false);
      props.onSaveClick(updatedGeneralInformationData, updatedMainContactData, updatedModuleManagementData);
    }
  }


  const companyPhotoChangeHandler = (event) => {
    if(event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if(file.size > 500000){
        alert('File size should be smaller than 450Kb');
        return false;				
      }

      var data = new FormData();
			data.append('profile_picture', file, file.name);
      props.onProfilePictureUploadClick(data, setProfilePictureUrl);
    }
  }


  return (
    <div className={styles['client-details-general-info']}>
      <div className={styles['company-information-form']}>
        <div className={styles['title']}>
          <h3>Company Information</h3>
        </div>
        {isEditMode && 
          <div className={styles['row']}>
            <div  className={styles['client-logo-upload-container']} >
              <div
                className={styles['client-logo']}
                style={{backgroundImage:`url("${profilePictureUrl}")`}}
              ></div>
              <label className={styles['client-logo-upload-button']}>
                <input type='file' accept='image/gif, image/jpeg, image/png' onChange={companyPhotoChangeHandler} />
                <h3>Change Company Logo</h3>
              </label>
            </div>
          </div>
        }
        <div className={styles['row']}>
          <TextInput
            id='general-info#investor-name'
            width='100%'
            label='Company Name *'
            placeholder='Enter Company Name'
            value={name}
            hasError={hasNameError}
            onBlur={nameBlurHandler}
            onChange={nameChangeHandler}
            isDisabled={!isEditMode}
          />
        </div>
        <div className={styles['row']}>
          <Dropdown
            id='subscription-details#client-type'
            width='100%'
            label='Client Type'
            placeholder='Select a Client Type Option'
            items={clientTypeDropdownItems}
            selected={clientType}
            hasError={hasClientTypeError}
            onBlur={clientTypeBlurHandler}
            onItemClick={clientTypeChangeHandler}
            isDisabled={!isEditMode}
          />
        </div>
        <div className={styles['row']}>
          <Dropdown
            id='general-info#region'
            width='100%'
            label='Region *'
            placeholder='Select a Region'
            items={regionDropdownItems}
            selected={region}
            hasError={hasRegionError}
            onBlur={regionBlurHandler}
            onItemClick={regionChangeHandler}
            isDisabled={!isEditMode}
          />
        </div>
        <div className={styles['row']}>
          <Dropdown
            id='general-info#country'
            width='100%'
            label='Country *'
            placeholder='Select a Country'
            items={countryDropdownItems}
            selected={country}
            hasSearchBox={true}
            hasError={hasCountryError}
            onBlur={countryBlurHandler}
            onItemClick={countryChangeHandler}
            isDisabled={!isEditMode}
          />
        </div>
        <div className={styles['row']}>
          <NumberInput
            id='general-info#total-num-of-shares'
            width='100%'
            label='Total # of Shares *'
            placeholder='Enter Total # of Shares'
            value={(clientType === 'Corporate') ? totalNumOfShares : '-'}
            hasError={hasTotalNumOfSharesError}
            onBlur={totalNumOfSharesBlurHandler}
            onChange={totalNumOfSharesChangeHandler}
            isDisabled={(clientType === 'Corporate') ? !isEditMode : true}
          />
        </div>
        <div className={styles['row']}>
          {clientType === 'Corporate' &&
            <DateInput
              id='general-info#date-num-of-shares'
              width='100%'
              label='Date Of # Of Shares'
              selected={dateNumOfShares}
              onChange={setDateNumOfShares}
              isDisabled={!isEditMode}
            />
          }
          {clientType === 'Non-Corporate' &&
            <TextInput
              id='general-info#date-num-of-shares'
              width='100%'
              label='Date Of # Of Shares'
              value='-'
              isDisabled={true}
            />
          }
        </div>
      </div>

      <div className={styles['main-contact-form']}>
        <div className={styles['title']}>
          <h3>Main Contact Information</h3>
        </div>
        <div className={styles['row']}>
          <TextInput
            id='main-contact#fullname'
            width='100%'
            label='Full Name *'
            placeholder='Enter Name and Surname'
            value={fullname}
            hasError={hasFullnameError}
            onBlur={fullnameBlurHandler}
            onChange={fullnameChangeHandler}
            isDisabled={!isEditMode}
          />
        </div>
        <div className={styles['row']}>
          <TextInput
            id='main-contact#job-title'
            width='100%'
            label='Job Title *'
            placeholder='Enter Job Title'
            value={jobTitle}
            hasError={hasJobTitleError}
            onBlur={jobTitleBlurHandler}
            onChange={jobTitleChangeHandler}
            isDisabled={!isEditMode}
          />
        </div>
        <div className={styles['row']}>
          <Dropdown
            id='main-contact#department'
            width='100%'
            label='Department *'
            placeholder='Select a Department'
            items={departmentDropdownItems}
            selected={department}
            hasError={hasDepartmentError}
            onBlur={departmentBlurHandler}
            onItemClick={departmentChangeHandler}
            isDisabled={!isEditMode}
          />
        </div>
        <div className={styles['row']}>
          <TextInput
            id='main-contact#email'
            width='100%'
            label='Email *'
            placeholder='Enter Email'
            value={email}
            hasError={hasEmailError}
            onBlur={emailBlurHandler}
            onChange={emailChangeHandler}
            isDisabled={!isEditMode}
          />
        </div>
        <div className={styles['row']}>
          <TextInput
            id='main-contact#phone-number'
            width='100%'
            label='Phone Number'
            placeholder='Enter Phone Number'
            value={phoneNumber}
            onChange={setPhoneNumber}
            isDisabled={!isEditMode}
          />
        </div>
        <div className={styles['row']}>
          <Dropdown
            id='main-contact#relationship-status'
            width='100%'
            label='Relationship Status'
            placeholder='Select a Relationship Status'
            items={relationshipStatusDropdownItems}
            hasChip={true}
            selected={relationshipStatus}
            onItemClick={setRelationshipStatus}
            isDisabled={!isEditMode}
          />
        </div>
      </div>

      <div className={styles['action-button-container']}>
        {isEditMode && 
          <Fragment>
            <Button size='base' type='cancel' onClick={cancelEditHandler}>Cancel</Button>
            <Button size='base' type='primary' onClick={updateGeneralInfoHandler}>Save</Button>
          </Fragment>
        }
        {!isEditMode && 
          <Fragment>
            <Button size='base' type='secondary' leftIcon='edit' onClick={() => setIsEditMode(true)}>Edit</Button>
          </Fragment>
        }
      </div>

      <div className={styles['available-modules-form']}>
        <div className={styles['row']}>
          <label className={styles['checkbox-group-label']}>Module Management</label>
          <div className={`${styles['column']} ${styles['half']}`}>
            <Checkbox
              id='dashboard-module-availability'
              value={isDashboardModuleActive}
              text='Dashboard Module'
              onChange={() => setIsDashboardModuleActive(prevState => !prevState)}
              isChecked={isDashboardModuleActive}
              isDisabled={!isEditMode}
            />
          </div>
          <div className={`${styles['column']} ${styles['half']}`}>
            <Checkbox
              id='esg-topics-module-availability'
              value={isESGTopicsModuleActive}
              text='ESG Topics Module'
              onChange={() => setIsESGTopicsModuleActive(prevState => !prevState)}
              isChecked={isESGTopicsModuleActive}
              isDisabled={!isEditMode}
            />
          </div>
          <div className={`${styles['column']} ${styles['half']}`}>
            <Checkbox
              id='investors-module-availability'
              value={isInvestorsModuleActive}
              text='Investors Module'
              onChange={() => setIsInvestorsModuleActive(prevState => !prevState)}
              isChecked={isInvestorsModuleActive}
              isDisabled={true}
            />
          </div>
          <div className={`${styles['column']} ${styles['half']}`}>
            <Checkbox
              id='investors-contacts-module-availability'
              value={isInvestorContactsModuleActive}
              text='Investors - Contacts Module'
              onChange={() => setIsInvestorContactsModuleActive(prevState => !prevState)}
              isChecked={isInvestorContactsModuleActive}
              isDisabled={!isEditMode}
            />
          </div>
          <div className={`${styles['column']} ${styles['half']}`}>
            <Checkbox
              id='proxy-advisors-module-availability'
              value={isProxyAdvisorsModuleActive}
              text='Proxy Advisors & Ratings Module'
              onChange={() => setIsProxyAdvisorsModuleActive(prevState => !prevState)}
              isChecked={isProxyAdvisorsModuleActive}
              isDisabled={true}
            />
          </div>
          <div className={`${styles['column']} ${styles['half']}`}>
            <Checkbox
              id='proxy-advisors-contacts-module-availability'
              value={isProxyAdvisorContactsModuleActive}
              text='Proxy Advisors & Ratings - Contacts Module'
              onChange={() => setIsProxyAdvisorContactsModuleActive(prevState => !prevState)}
              isChecked={isProxyAdvisorContactsModuleActive}
              isDisabled={!isEditMode}
            />
          </div>
          <div className={`${styles['column']} ${styles['half']}`}>
            <Checkbox
              id='engagement-meeting-requests-module-availability'
              value={isEngagementMeetingRequestsModuleActive}
              text='Engagement - Meeting Requests Module'
              onChange={() => setIsEngagementMeetingRequestsModuleActive(prevState => !prevState)}
              isChecked={isEngagementMeetingRequestsModuleActive}
              isDisabled={!isEditMode}
            />
          </div>
          <div className={`${styles['column']} ${styles['half']}`}>
            <Checkbox
              id='engagement-shareholder-communications-module-availability'
              value={isEngagementShareholderCommunicationsModuleActive}
              text='Engagement - Shareholder Communications Module'
              onChange={() => setIsEngagementShareholderCommunicationsModuleActive(prevState => !prevState)}
              isChecked={isEngagementShareholderCommunicationsModuleActive}
              isDisabled={!isEditMode}
            />
          </div>
          <div className={`${styles['column']} ${styles['half']}`}>
            <Checkbox
              id='engagement-meeting-notes-module-availability'
              value={isEngagementMeetingNotesModuleActive}
              text='Engagement - Meeting Notes Module'
              onChange={() => setIsEngagementMeetingNotesModuleActive(prevState => !prevState)}
              isChecked={isEngagementMeetingNotesModuleActive}
              isDisabled={!isEditMode}
            />
          </div>
          <div className={`${styles['column']} ${styles['half']}`}>
            <Checkbox
              id='engagement-other-notes-module-availability'
              value={isEngagementOtherNotesModuleActive}
              text='Engagement - Other Notes Module'
              onChange={() => setIsEngagementOtherNotesModuleActive(prevState => !prevState)}
              isChecked={isEngagementOtherNotesModuleActive}
              isDisabled={!isEditMode}
            />
          </div>
          <div className={`${styles['column']} ${styles['half']}`}>
            <Checkbox
              id='docusearch-module-availability'
              value={isDocuSearchModuleActive}
              text='DocuSearch Module'
              onChange={() => setIsDocuSearchModuleActive(prevState => !prevState)}
              isChecked={isDocuSearchModuleActive}
              isDisabled={!isEditMode}
            />
          </div>
          <div className={`${styles['column']} ${styles['half']}`}>
            <Checkbox
              id='quickvote-module-availability'
              value={isQuickVoteModuleActive}
              text='QuickVote Module'
              onChange={() => setIsQuickVoteModuleActive(prevState => !prevState)}
              isChecked={isQuickVoteModuleActive}
              isDisabled={!isEditMode}
            />
          </div>
          <div className={`${styles['column']} ${styles['half']}`}>
            <Checkbox
              id='research-module-availability'
              value={isReSearchModuleActive}
              text='ReSearch Module'
              onChange={() => setIsReSearchModuleActive(prevState => !prevState)}
              isChecked={isReSearchModuleActive}
              isDisabled={!isEditMode}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetailsGeneralInfo;
