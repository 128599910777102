import { useEffect, useState } from 'react';
import { useResizeDetector } from "react-resize-detector";
import { useNavigate } from 'react-router-dom';
import { clientMenuItems } from "../../../utils/constants";
import { getPageStyle, updatePageStyle } from '../../../utils/helpers';
import { checkIsAccountManagerViewMode, getAuthClientId, getAuthClientName, getAuthUserProfilePictureUrl, getClientAvailableModules, setLocalStorageClientData } from "../../../utils/auth";
import { fetchClientBasicProfileRequest } from '../../../requests/client-requests';
import { fetchPowerBiQuickVoteCredentials } from '../../../requests/esgds-requets';
import { handleRequestError } from '../../../utils/request-error-helpers';
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import useHttp from '../../../hooks/use-http';

import AccountManagerModeHeader from "../../../components/AccountManagerModeHeader/AccountManagerModeHeader";
import SideNavigationBar from "../../../components/UI/SideNavigationBar/SideNavigationBar";

import styles from './QuickVotePage.module.css';

const QuickVotePage = (props) => {
  const navigate = useNavigate();

  /* eslint-disable no-unused-vars */
  const { width, height, ref } = useResizeDetector();
  const [pageStyle, setPageStyle] = useState(getPageStyle());
  const pageHeightMargin = checkIsAccountManagerViewMode() ? '161px' : '95px';
  /* eslint-disable no-unused-vars */

  const [isLoading, error, sendRequest] = useHttp();

  const clientId = getAuthClientId();
  const clientAvailableModules = getClientAvailableModules();
  const userProfilePictureUrl = getAuthUserProfilePictureUrl();

  const [clientProfilePictureUrl, setClientProfilePictureUrl] = useState(null);
  const [quickVoteEmbedToken, setQuickVoteEmbedToken] = useState(null);
  const [quickVoteEmbedUrl, setQuickVoteEmbedUrl] = useState(null);

  useEffect(() => {
    sendRequest(
      fetchClientBasicProfileRequest(clientId),
      (dataClient) => {
        setLocalStorageClientData(dataClient);
        setClientProfilePictureUrl(dataClient['profile_picture_url']);

        if(dataClient['module_management']['quickvote']) {
          sendRequest(
            fetchPowerBiQuickVoteCredentials(),
            (dataPowerBiQuickVote) => {
              setQuickVoteEmbedToken(dataPowerBiQuickVote['embed_token']);
              setQuickVoteEmbedUrl(dataPowerBiQuickVote['embed_url']);
            }
          );
        }
        else {
          if(dataClient['module_management']['dashboard']) {
            window.location.href = 'dashboard';
          }
          else {
            window.location.href = 'investors';
          }
        }
      }
    );
  }, [sendRequest, clientId]);
  
  if(!isLoading && error !== undefined) {
    console.log(error);
    handleRequestError(error);
  }

  useEffect(() => {
    if(clientAvailableModules["quickvote"] === false) {
      navigate('/login');
    }
  }, [navigate, clientAvailableModules]);

  return (
    <div className={styles['page']}>
      {checkIsAccountManagerViewMode() &&
        <AccountManagerModeHeader
          type={pageStyle === 'wide' ? 'wide' : 'narrow'}
          clientName={getAuthClientName()}
          exitRedirecUrl='../clients'
        />
      }

      <SideNavigationBar
        menuItems={clientMenuItems.filter(item => clientAvailableModules[item['module_id']])}
        profilePictureUrl={userProfilePictureUrl === '' ? clientProfilePictureUrl : userProfilePictureUrl}
        selected='quickvote'
        type={pageStyle === 'wide' ? 'narrow' : 'wide'}
        onToggle={() => {
          if (pageStyle === 'narrow') {
            setPageStyle('wide');
            updatePageStyle('wide');
          }
          else {
            setPageStyle('narrow');
            updatePageStyle('narrow');
          }
        }}
      />

      <div
        className={`${styles['container']} ${styles[checkIsAccountManagerViewMode() ? 'with-account-manager-warning' : '']} ${styles[pageStyle]}`}
        style={{ height: `calc(100% - ${pageHeightMargin})`, overflowY: 'auto', overflowX: 'hidden' }}
        ref={ref}
      >
        <div className={styles['quickvote-container']}>
          <div className={styles['row']}>
            {quickVoteEmbedUrl && quickVoteEmbedToken &&
              <PowerBIEmbed
                embedConfig={{
                  type: 'report',
                  embedUrl: quickVoteEmbedUrl,
                  accessToken: quickVoteEmbedToken,
                  tokenType: models.TokenType.Embed,
                }}
                cssClassName={styles['powerbi-container']}
                onEmbedded={(embed) => console.log('Embedded:', embed)}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuickVotePage;